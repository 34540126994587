.ContactConfirm {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: #3d3d3d;
    color: #ffffff;
    font-weight: 500;
    width: 100%;
    border-radius: 0.5rem;
}

.threebobas {
    width: 675px;
}

.backgroundbubbleanimation {
    animation: bobacolorchange 1.25s ease-in-out infinite alternate;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
}

#background_bulkorder {
    background-image: url("../SiteImg/Rectangle\ 31.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: calc(100%);
    padding: 25px;
}

.poppin_contact {
    font-family: 'Poppins', sans-serif;
    ;
}

@keyframes bobacolorchange {

    from {
        background-image: url('../SiteImg/BOBA\ animation.png');
    }

    to {
        background-image: url('../SiteImg/BOBA\ animation\ \(1\).png');

    }
}

@media only screen and (min-width: 768px) {
    @keyframes bobacolorchange {

        from {
            background-image: url('../SiteImg/1boba_anime.png');
        }

        to {
            background-image: url('../SiteImg/boba_anime_after.png');

        }
    }
}