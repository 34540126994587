.react-calendar__navigation button {
  display: none;
}

.react-calendar {
  background-color: #f8e8d4;
  width: 100%;
  padding: 40px;
  color: #222;
  border-radius: 10px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.5);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1.125em;
  border: none;
}

/* .react-calendar__navigation button {
  color: black;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
} */

/* .react-calendar__navigation__label {
  color: black;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
} */

/* .react-calendar__navigation__label {
  pointer-events: none;
} */

/* abbr[title] {
  text-decoration: none;
} */

/* .react-calendar__month-view__days__day--weekend {
 color: #d10000;
} */
/* .react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next-button {
  border-radius: 100%;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev-button {
  border-radius: 100%;
} */

.react-calendar__tile {
  background: white;
  padding: 0;
  position: relative;
  width: 100% !important;
  border-radius: 5px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: rgb(255, 29, 83, 0.1);
  color: black;
}

.react-calendar__tile--now {
  font-weight: bold;
  color: black;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: rgb(255, 29, 83, 0.1);
  font-weight: bold;
  color: black;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: rgb(255, 29, 83, 0.1);
  border: solid;
}

.react-calendar__tile--active {
  background: rgb(255, 29, 83, 0.1);
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: rgb(255, 29, 83, 0.1);
  color: black;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background: #f8f8fa;
  color: rgb(255, 29, 83, 0.1);
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: rgb(255, 29, 83, 0.1);
  color: black;
}

.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: rgb(255, 29, 83, 0.1);
  color: black;
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}

.react-calendar__month-view__days {
  gap: 4px;
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.6;
}
