.catering-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin: 20px;
    text-align: center;

    .catering-header {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 20px;
        color:rgb(88, 38, 1)
    }

    .company-catering-header {
        font-size: 1.5rem;
        @media (max-width: 768px) {
            font-size: 1rem;
        }
        @media (max-width: 480px) {
            font-size: 1rem;
        }
        margin-bottom: 20px;
        color:rgb(88, 38, 1);
        text-align: center;
    }

    .company-catering {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .catering-button {
        background-color: rgb(247, 160, 94);
        color: white;
        font-size: 1.5rem;
        padding: 10px 20px;
        border: none;
        border-radius: 3px;
        margin-top: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: rgb(88, 38, 1, 0.8);
        }
    }
}
