html {
  background: #FFF9F0;
  background-repeat: no-repeat;
  background-size: auto 150%;
}

body {
  font-family: "Montserrat", sans-serif;
}

svg {
  color: #3d3d3d;
  opacity: 0.8;
}

svg:hover {
  opacity: 1;
}

strong {
  font-size: 45px;
}


.menu-border {
  border-top: 1px solid #3E3E3E;
}

.tea-making-man {
  width: 75%;
  height: 95%;
}

.tea-making-man2 {
  display: none;
}

.tea-making-man4 {
  display: none;
}

.comic-bg {
  background-color: #FFF9F0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerbgc {
  background-color: #FAEFDF
}

.grayLoadMain {
  background-color: rgb(220, 220, 220);
}

.bg-Img-landing {
  overflow: hidden;
  background: url("https://i.imgur.com/vTHCGGG.jpg") center center no-repeat;
  background-size: cover;
  height: 400px;
}

/* group-hover:scale-105 transition-transform duration-500 object-cover w-full h-[300px] sm:h-[900px] */

.avenir {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.underlined {
  border-bottom: 2px solid;
}

.border-bottom-our-story {
  border-bottom: 2px solid;
}

.underlined:hover {
  opacity: 0.75;
}

.hamburger-icon {
  color: #3d3d3d;
  opacity: 1;
}

.FAQ-Component {
  color: #323232;
  font-family: "Maven Pro", sans-serif;
  margin-top: 36px;
}

.FAQ-bold {
  font-weight: 700;
}

/* .man-making-tea-container {
  display: flex;
  justify-content: flex-end;
} */

.man-making-tea-img {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}

.neutral-block {
  display: inline-block;
  background-color: #eb905a;
  width: 4rem;
  height: 4px;
}

.maven {
  font-family: "Maven Pro", sans-serif;
  font-weight: bold;
  font-size: 10px;
  margin-top: 1rem;
}

.Intro-Container {
  display: flex;
  flex-direction: column;
}

.poppins-change {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.poppins-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-family: "Poppins", sans-serif;
  font-size: 34px;
  background-color: #f3e0c2;
}

.poppins-bolded {
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.poppins-faq {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  margin-left: 18px;
}

.maven-text-statement {
  display: none;
}

.maven-grassjelly {
  font-family: "Maven Pro", sans-serif;
  font-weight: bold;
  color: #c36a3e;
  font-size: 32px;
}

.maven-statement-heading {
  font-family: "Maven Pro", sans-serif;
  font-weight: bold;
  color: #eb905a;
  font-size: 48px;
}

.background-cream {
  background-color: #f3e0c2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}

/* class="group-hover:scale-105 transition-transform duration-500 object-cover w-full h-[500px] sm:h-[500px]" */

.maven-statement-heading2 {
  font-family: "Maven Pro", sans-serif;
  font-weight: bold;
  color: #3d3d3d;
  font-size: 16px;
  margin-top: 12px;
}

.nav-ul-container {
  font-family: "Poppins", sans-serif;
  color: black;
  font-size: 32px;
  margin-top: 40px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  margin-top: 4px;
}

.poppins {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.poppins-header-responsive {
  background-color: #f3e0c2;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 12px;
}

.poppins-bolded-responsive {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  margin-bottom: -4px;
}

.hide {
  display: none;
}

.hamburger-icon {
  background-image: linear-gradient(#f3e0c2, #eb905a) 3c2;
  height: 32px;
  border-radius: 4px;
}

.comic-container {}

@media only screen and (min-width: 640px) {
  .modal-menu {
    width: 90%;
    transform: rotateZ(0deg);
  }

  .poppins-bolded-responsive {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 700;
    display: flex;
    justify-content: center;
  }

  .poppins-bolded {
    display: flex;
    justify-content: center;
    font-size: 48px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
  }

  .border-bottom-our-story {
    border-bottom: 0px;
  }

  .comic-container {
    display: flex;
    width: 110%;
    margin-right: 24px;
    background-color: #eb905a;
  }

  /* About */


  .tea-making-man {
    width: 67%;
    margin-bottom: 2.5%;
  }

  /* About Animation */

  @keyframes fadeInOutAnimationRight {
    0% {
      transform: translate3d(50px, 0, 0);
      opacity: 0;
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0.25s all ease-in-out;
    }
  }

  @keyframes fadeInOutAnimationTop {
    0% {
      transform: translate3d(0, -50px, 0);
      opacity: 0;
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0.25s all ease-in-out;
    }
  }

  @keyframes fadeInOutAnimationBottom {
    0% {
      transform: translate3d(0, 50px, 0);
      opacity: 0;
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0.25s all ease-in-out;
    }
  }

  @keyframes fadeInOutAnimationLeft {
    0% {
      transform: translate3d(-50px, 0, 0);
      opacity: 0;
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0.25s all ease-in-out;
    }
  }

  .man-making-tea-container {
    display: flex;
    width: 150%;
    margin-top: 25px;
  }

  .poppins {
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    display: flex;
    justify-content: center;
  }

  .bg-Img-landing {
    overflow: hidden;
    background: url("https://i.imgur.com/vTHCGGG.jpg") center center no-repeat;
    background-size: cover;
    height: 600px;
  }


}

@media only screen and (min-width: 768px) {
  .poppins-bolded {
    display: flex;
    justify-content: center;
    font-size: 72px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
  }

  .man-making-tea-container {
    display: flex;
    width: 125%;
    margin-top: 25px;
  }

  .maven {
    font-family: "Maven Pro", sans-serif;
    font-weight: bold;
    font-size: 18px;
    margin-top: 1rem;
  }

  .maven-statement-heading2 {
    font-family: "Maven Pro", sans-serif;
    font-weight: bold;
    color: #3d3d3d;
    font-size: 36px;
  }

  .poppins {
    font-family: "Poppins", sans-serif;
    font-size: 40px;
    display: flex;
    justify-content: center;
  }

  .poppins-bolded-responsive {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 700;
  }

  .poppins-change {
    font-family: "Poppins", sans-serif;
    font-size: 100px;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  .background-cream {
    background-color: #f3e0c2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    padding-top: 36px;
  }

  .border-bottom-our-story {
    border-bottom: 0px;
  }

  .bg-Img-landing {
    overflow: hidden;
    background: url("https://i.imgur.com/vTHCGGG.jpg") center center no-repeat;
    background-size: cover;
    height: 800px;
  }

  .tea-making-man {
    width: 45%;
    margin-bottom: 4%;
  }

  .tea-making-man2 {
    width: 45%;
    display: block;
    margin-bottom: 4%;
  }
}

@media only screen and (min-width: 1024px) {

  .tea-making-man {
    width: 29%;
  }

  .tea-making-man2 {
    width: 29%;
  }

  .tea-making-man4 {
    width: 29%;
    display: block;
    margin-bottom: 4%;
  }

  .tea-making-man4 {
    transform: translate3d(0, 0, 0);
    transition: 0.25s all ease-in-out;
  }

  .tea-making-man3 {
    transform: translate3d(0, 0, 0);
    transition: 0.25s all ease-in-out;
  }

  .tea-making-man2 {
    transform: translate3d(0, 0, 0);
    transition: 0.25s all ease-in-out;
  }

  .tea-making-man {
    transform: translate3d(0, 0, 0);
    transition: 0.25s all ease-in-out;
  }

  .man-making-tea-container {
    display: flex;
    width: 100%;
    margin-top: 25px;
  }

  .teas-img-container {
    display: flex;
    object-fit: cover;
    width: 100%;
    height: 500px;
    margin-top: 25px;
  }

  .maven-text-statement {
    font-family: "Maven Pro", sans-serif;
    font-weight: 900;
    margin-top: 25px;
    margin-left: 24px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px;
  }

  .maven {
    font-family: "Maven Pro", sans-serif;
    font-weight: bold;
    font-size: 24px;
    margin-top: 1rem;
  }

  .maven-statement-heading2 {
    font-family: "Maven Pro", sans-serif;
    font-weight: bold;
    color: #3d3d3d;
    font-size: 48px;
  }

  .poppins-change {
    font-family: "Poppins", sans-serif;
    font-size: 150px;
    display: flex;
    justify-content: center;
    margin-bottom: 75px;
  }

  .background-cream {
    background-color: #f3e0c2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .poppins-bolded-responsive {
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-weight: 700;
  }

  .man-making-tea-img {
    display: block;
    height: 50%;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .border-bottom-our-story {
    border-bottom: 4px solid;
  }

  .bg-Img-landing {
    overflow: hidden;
    background: url("https://i.imgur.com/vTHCGGG.jpg") center center no-repeat;
    background-size: cover;
    height: 1000px;
  }

  .comic-h {
    height: 100%;
    width: 100%;
  }
}

/* About Page */