/* ImageGallery.css */
.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 16px;
  }
  
  .gallery-item {
    position: relative; /* Positioning context for the overlay */
    box-sizing: border-box;
    flex: 1 0 21%; /* Adjust the percentage to control the size of the images */
    margin-bottom: 10px;
    overflow: hidden; /* Ensure the overlay doesn't spill out */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0; /* Background color for when images are loading */
    animation: scaleUp 1s ease-in-out 1s; /* Apply the animation with delay */
  }
  
  .gallery-item img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    position: relative;
    z-index: 1; /* Place the image above the blurred background */
    transition: filter 0.3s ease; /* Smooth transition for blur effect */
    animation: scaleUp 1s ease-in-out 1s; /* Apply the animation with delay */
  }
  
  .gallery-item:hover img {
    filter: blur(5px); /* Apply blur on hover */
  }
  
  .gallery-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: blur(10px);
    z-index: 0; /* Place the blurred background behind the image */
    transition: opacity 0.3s ease; /* Smooth transition for the blur effect */
    opacity: 0; /* Hidden by default */
  }
  
  .gallery-item:hover::before {
    opacity: 1; /* Show the blurred background on hover */
  }
  
  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: calc(10px + 1vw);
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease; /* Smooth transition for text */
    pointer-events: none; /* Ensure the overlay doesn't block hover */
    z-index: 2; /* Place the overlay above everything else */
  }
  
  .gallery-item:hover .overlay {
    opacity: 1; /* Show the text overlay on hover */
  }
  
  /* Keyframes for the scale-up animation */
  @keyframes scaleUp {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.02); /* More subtle scale */
    }
    100% {
      transform: scale(1);
    }
  }
  